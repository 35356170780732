export default {
    required: (v: string | number) : string | boolean => {
        return (!!v || v === 0)  || 'Campo obrigatório'
    },
    email: (v: string) : string | boolean => /.+@.+\..+/.test(v) || 'E-mail inválido',
    minLength: (v: string, min: number) => (v && v.length >= min) || `Mínimo de ${min} caracteres`,
    maxLength: (v: string, max: number) => (v && v.length <= max) || `Máximo de ${max} caracteres`,
    numeric: (v: string) => {
        !isNaN(parseFloat(v)) || 'Campo numérico'
    },
    minNumber: (v: string, min: number) => (v && parseFloat(v) >= min) || `Mínimo de ${min}`,
    maxNumber: (v: string, max: number) => (v && parseFloat(v) <= max) || `Máximo de ${max}`,
    isEqual: (v: string, v2: string) : string | boolean => (v === v2) || 'Senhas não conferem'
}