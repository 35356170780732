import { FormMode } from "@/consts/form";
import { EmployeeSubstitutionsState } from "../types/state";
import { ActionContext } from "vuex";
import axiosAuth from "@/config/axios";
import { DefaultResponse } from "@/types/response";
import { endPoints } from "@/config/api";
import { useNotification } from "@/composables/useNotification";
import { IRootState } from "@/types/state";
import { EmployeeSubstitution, IFilterEmployeeSubstitutions } from "../types/employee_substitution";
import { defaultEmployeeSubstitution, defaultFilterEmployeeSubstitutions } from "../utils/consts";

const { notifySuccess, notifyError } = useNotification();

const state: () => EmployeeSubstitutionsState = () => ({
    employee_substitutions: [],
    showFormDialog: false,
    showDeleteDialog: false,
    filterEmployeeSubstitutions: Object.assign({}, defaultFilterEmployeeSubstitutions),
    isFilterDialogOpen: false,
    selectedEmployeeSubstitution: defaultEmployeeSubstitution,
    formMode: FormMode.VIEW,
    pagination: {
        page: 1,
        itemsPerPage: 10,
        total: 0
    }
});

const mutations = {
    SET_EMPLOYEE_SUBSTITUTIONS(state: EmployeeSubstitutionsState, payload: { employee_substitution: EmployeeSubstitution[] }) {
        state.employee_substitutions = payload.employee_substitution;
    },
    SHOW_FORM_DIALOG(state: EmployeeSubstitutionsState, payload: { show: boolean }) {
        state.showFormDialog = payload.show;
    },
    SET_SELECTED_EMPLOYEE_SUBSTITUTION(state: EmployeeSubstitutionsState, payload: { employee_substitution: EmployeeSubstitution }) {
        state.selectedEmployeeSubstitution = Object.assign({}, payload.employee_substitution);
    },
    REMOVE_EXCEPTIONS(state: EmployeeSubstitutionsState) {
        state.selectedEmployeeSubstitution.exceptions = [];
    },
    SET_FORM_MODE(state: EmployeeSubstitutionsState, payload: { mode: FormMode }) {
        state.formMode = payload.mode;
    },
    SET_SHOW_DELETE_DIALOG(state: EmployeeSubstitutionsState, payload: { show: boolean }) {
        state.showDeleteDialog = payload.show;
    },
    SET_FILTER_DIALOG_OPEN(state: EmployeeSubstitutionsState, isOpen: boolean) {
        state.isFilterDialogOpen = isOpen;
    },
    SET_FILTER_EMPLOYEE_SUBSTITUTIONS(state: EmployeeSubstitutionsState, filter: IFilterEmployeeSubstitutions) {
        state.filterEmployeeSubstitutions = filter;
    }


};

const actions = {

    openDeleteDialog({ commit }: ActionContext<EmployeeSubstitutionsState, IRootState>, payload: { employee_substitution: EmployeeSubstitution }) {
        commit('SET_SELECTED_EMPLOYEE_SUBSTITUTION', { employee_substitution: payload.employee_substitution });
        commit('SET_SHOW_DELETE_DIALOG', { show: true });
    },
    closeDeleteDialog({ commit }: ActionContext<EmployeeSubstitutionsState, IRootState>) {
        commit('SET_SHOW_DELETE_DIALOG', { show: false });
        commit('SET_SELECTED_EMPLOYEE_SUBSTITUTION', { employee_substitution: defaultEmployeeSubstitution });
    },
    openEditEmployeeSubstitutionDialog({ commit, dispatch }: ActionContext<EmployeeSubstitutionsState, IRootState>, payload: { employee_substitution: EmployeeSubstitution }) {
        commit('SET_FORM_MODE', { mode: FormMode.EDIT });
        if (payload.employee_substitution.clientsExceptions && payload.employee_substitution.clientsExceptions.length > 0) {
            payload.employee_substitution.exceptions = payload.employee_substitution.clientsExceptions.map((client: any) => client?.client.id);
            payload.employee_substitution.substitutionAllClients = false;
            dispatch('clients/fetchClientsByEmployee', { employeeId: payload.employee_substitution.fixedEmployeeId }, { root: true });
        } else {
            payload.employee_substitution.substitutionAllClients = true;
        }
        commit('SET_SELECTED_EMPLOYEE_SUBSTITUTION', { employee_substitution: payload.employee_substitution });
        commit('SHOW_FORM_DIALOG', { show: true });
    },

    openViewEmployeeSubstitutionDialog({ commit, dispatch }: ActionContext<EmployeeSubstitutionsState, IRootState>, payload: { employee_substitution: EmployeeSubstitution }) {
        commit('SET_FORM_MODE', { mode: FormMode.VIEW });
        if (payload.employee_substitution.clientsExceptions && payload.employee_substitution.clientsExceptions.length > 0) {
            payload.employee_substitution.exceptions = payload.employee_substitution.clientsExceptions.map((client: any) => client?.client.id);
            payload.employee_substitution.substitutionAllClients = false;
            dispatch('clients/fetchClientsByEmployee', { employeeId: payload.employee_substitution.fixedEmployeeId }, { root: true });
        } else {
            payload.employee_substitution.substitutionAllClients = true;
        }

        console.log(payload.employee_substitution);

        commit('SET_SELECTED_EMPLOYEE_SUBSTITUTION', { employee_substitution: payload.employee_substitution });
        commit('SHOW_FORM_DIALOG', { show: true });
    },

    openFilterDialog({ commit }: ActionContext<EmployeeSubstitutionsState, IRootState>) {
        commit('SET_FILTER_DIALOG_OPEN', true);
        commit('SET_FILTER_EMPLOYEE_SUBSTITUTIONS', Object.assign({}, defaultFilterEmployeeSubstitutions));

    },
    closeFilterDialog({ commit }: ActionContext<EmployeeSubstitutionsState, IRootState>) {
        commit('SET_FILTER_DIALOG_OPEN', false);
        commit('SET_FILTER_EMPLOYEE_SUBSTITUTIONS', Object.assign({}, defaultFilterEmployeeSubstitutions));
    },

    clearFilter({ commit }: ActionContext<EmployeeSubstitutionsState, IRootState>) {
        commit('SET_FILTER_EMPLOYEE_SUBSTITUTIONS', Object.assign({}, defaultFilterEmployeeSubstitutions));
    },

    openCreateDialog({ commit }: ActionContext<EmployeeSubstitutionsState, IRootState>) {
        commit('SET_FORM_MODE', { mode: FormMode.ADD });
        commit('SET_SELECTED_EMPLOYEE_SUBSTITUTION', { employee_substitution: defaultEmployeeSubstitution });
        commit('SHOW_FORM_DIALOG', { show: true });
    },

    closeDialog({ commit }: ActionContext<EmployeeSubstitutionsState, IRootState>) {
        commit('SHOW_FORM_DIALOG', { show: false });
    },
    async fetchEmployeeSubstitutions({ commit, state }: ActionContext<EmployeeSubstitutionsState, IRootState>,) {
        try {
            const params: any = {};

            for (const key in state.filterEmployeeSubstitutions) {
                const value = state.filterEmployeeSubstitutions[key as keyof typeof state.filterEmployeeSubstitutions];
                if (value || value === 0) {
                    params[key] = value;
                }
            }
            params.page = state.pagination.page;
            params.itemsPerPage = state.pagination.itemsPerPage;

            const response = await axiosAuth.get<DefaultResponse<EmployeeSubstitution>>(`${endPoints.employee_substitutions}`, { params });
            console.log(response.data.data);
            commit('SET_EMPLOYEE_SUBSTITUTIONS', { employee_substitution: response.data.data });
        } catch (error) {
            notifyError('Erro ao buscar coberturas');
        }
    },

    async saveEmployeeSubstitution({ commit, state, dispatch }: ActionContext<EmployeeSubstitutionsState, IRootState>) {
        state.selectedEmployeeSubstitution.fixedEmployeeId = state.selectedEmployeeSubstitution.fixedEmployee?.id || '';
        state.selectedEmployeeSubstitution.substituteEmployeeId = state.selectedEmployeeSubstitution.substituteEmployee?.id || '';
        const { fixedEmployee, substituteEmployee, clientsExceptions,substitutionAllClients, id, createdAt, ...data } = state.selectedEmployeeSubstitution;

        try {
            let response;
            if (state.formMode === FormMode.ADD) {

                response = await axiosAuth.post<DefaultResponse<EmployeeSubstitution>>(endPoints.employee_substitutions, data);
            } else {

                response = await axiosAuth.put<DefaultResponse<EmployeeSubstitution>>(`${endPoints.employee_substitutions}/${state.selectedEmployeeSubstitution.id}`, data);
            }
            notifySuccess('Cobertura salva com sucesso');
            dispatch('fetchEmployeeSubstitutions');
            commit('SHOW_FORM_DIALOG', { show: false });
        } catch (error) {
            notifyError('Erro ao salvar cobertura');
        }
    },

    async deleteEmployeeSubstitution({ commit, state, dispatch }: ActionContext<EmployeeSubstitutionsState, IRootState>) {
        try {
            await axiosAuth.delete(`${endPoints.employee_substitutions}/${state.selectedEmployeeSubstitution.id}`);
            notifySuccess('Cobertura deletada com sucesso');
            dispatch('fetchEmployeeSubstitutions');
            commit('SET_SELECTED_EMPLOYEE_SUBSTITUTION', { employee_substitution: defaultEmployeeSubstitution });
            commit('SET_SHOW_DELETE_DIALOG', { show: false });
        } catch (error) {
            notifyError('Erro ao deletar cobertura');
        }
    }





};

export default {
    namespaced: true,
    state,
    mutations,
    actions
};