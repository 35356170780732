import { IFilterAvaliation } from "./types/avaliations";

export const units = [
    { text: "Unidade", value: "UN" },
    { text: "Quilograma", value: "KG" },
    { text: "Litro", value: "LT" },
    { text: "Metro", value: "MT" },
]

export const defaultFilterAvaliation: IFilterAvaliation = {
    inspectionDate:null,
    inspectionDuration: null,
    roomOrganized: false,
    sectorState: null,
    hasTimeline: false,
    clientPresent: false,
    clientSatisfaction: null,
    companySatisfaction: null,
    clientId: '',
  };