import { RouteRecordRaw } from 'vue-router';

const clientRoutesRoutes : Array<RouteRecordRaw> = [
    {
        path: '/routes',
        name: 'routes',
        component: () => import( './views/ClientRoutesView.vue'),
        meta: {
            requiresAuth: true
        }
    },
    
]

export default clientRoutesRoutes;