import { FormMode } from "@/consts/form";
import { ResponsiblesState } from "../types/state";
import { ActionContext } from "vuex";
import axiosAuth from "@/config/axios";
import { DefaultResponse } from "@/types/response";
import { endPoints } from "@/config/api";
import { useNotification } from "@/composables/useNotification";
import { ClientResponsible, IFilterResponsible, Responsibles } from "../types/responsibles";
import { IRootState } from "@/types/state";
import { defaultClient } from "@/modules/client/consts";
import { Client } from "@/modules/client/types/client";

const { notifySuccess, notifyError } = useNotification();

const defaultResponsibles: Responsibles = {
    id: undefined,
    birthDate: null,
    addressCep: '',
    addressCity: '',
    addressState: '',
    addressNeighborhood: '',
    address: '',
    addressNumber: '',
    email: '',
    name: '',
    phone: '',
    createdAt: new Date(),
    createdBy: '',
    startedAt: new Date(),
    finishedAt: null,
    updatedAt: null,
    updatedBy: null,
};
const defaultClientResponsible : ClientResponsible = {
    clientId: '',
    responsibleId: '',
    startedAt: new Date(),
    finishedAt: null,
    status: undefined,
}

const state: () => ResponsiblesState = () => ({
    selectedClientResponsible: Object.assign({}, defaultClientResponsible),
    filter: {
        search: '',
        name: '',
        doc: '',
    },
    responsibles: [],
    pagination: {
        page: 1,
        itemsPerPage: 10,
        total: 0
    },
    selectedResponsible: Object.assign({}, defaultResponsibles),
    formMode: FormMode.ADD,
    showFormDialog: false,
    showDeleteDialog: false,
    showDialogLinkClientResponsible: false,
    employeeRatings: {},
    showFilterDialog: false
});

const mutations = {
    SET_RESPONSIBLES(state: ResponsiblesState, payload: { responsibles: Responsibles[] }) {
        state.responsibles = payload.responsibles;
    },
    SHOW_FORM_DIALOG(state: ResponsiblesState, payload: { show: boolean }) {
        state.showFormDialog = payload.show;
    },
    SHOW_DIALOG_LINK_CLIENT_RESPONSIBLE(state: ResponsiblesState, payload: { show: boolean }) {
        state.showDialogLinkClientResponsible = payload.show;
    },
    SET_SELECTED_RESPONSIBLE(state: ResponsiblesState, payload: { responsibles: Responsibles }) {
        state.selectedResponsible = Object.assign({}, payload.responsibles);
    },
    SET_FORM_MODE(state: ResponsiblesState, payload: { mode: FormMode }) {
        state.formMode = payload.mode;
    },
    SET_EMPLOYEE_RATINGS(state: ResponsiblesState, payload: { employeeRatings: { [key: string]: number } }) {
        state.employeeRatings = payload.employeeRatings;
    },
    SET_CLIENT_RESPONSIBLE(state: ResponsiblesState, payload: { selectedClientResponsible: ClientResponsible }) {
        state.selectedClientResponsible = Object.assign({}, payload.selectedClientResponsible);
    },
    SET_TOTAL(state: ResponsiblesState, payload: { total: number }) {
        state.pagination.total = payload.total;
    },
    SHOW_FILTER_DIALOG(state: ResponsiblesState, payload: { show: boolean }) {
        state.showFilterDialog = payload.show;
    }
};

const actions = {
    openEditResponsibleDialog({ commit }: ActionContext<ResponsiblesState, IRootState>, payload: { responsible: Responsibles }) {
        if(typeof payload.responsible.birthDate === 'string') payload.responsible.birthDate = new Date(payload.responsible.birthDate);
        commit('SET_FORM_MODE', { mode: FormMode.EDIT });
        commit('SET_SELECTED_RESPONSIBLE', { responsibles: payload.responsible });
        commit('SHOW_FORM_DIALOG', { show: true });
    },
    openViewResponsibleDialog({ commit }: ActionContext<ResponsiblesState, IRootState>, payload: { responsible: Responsibles }) {
        commit('SET_FORM_MODE', { mode: FormMode.VIEW });
        commit('SET_SELECTED_RESPONSIBLE', { responsibles: payload.responsible });
        commit('SHOW_FORM_DIALOG', { show: true });
    },
    openCreateDialog({ commit }: ActionContext<ResponsiblesState, IRootState>, payload?: { client: Client }) {
       
        let responsible = Object.assign({}, defaultResponsibles);
        if(payload?.client) {
            responsible = {
                ...responsible,
                addressCity: payload.client.addressCity,
                addressState: payload.client.addressState,
                addressNeighborhood: payload.client.addressNeighborhood,
                address: payload.client.address,
                addressNumber: payload.client.addressNumber,
                addressCep: payload.client.addressCep,
            }
        }
        commit('SET_FORM_MODE', { mode: FormMode.ADD });
        commit('SET_SELECTED_RESPONSIBLE', { responsibles: responsible });
        commit('SHOW_FORM_DIALOG', { show: true });
    },
    closeDialog({ commit }: ActionContext<ResponsiblesState, IRootState>) {
        commit('SHOW_FORM_DIALOG', { show: false });
    },
    closeFilterDialog({ commit }: ActionContext<ResponsiblesState, IRootState>) {
        commit('SHOW_FILTER_DIALOG', { show: false });
    },
    openDialogLinkClientResponsible({ commit }: ActionContext<ResponsiblesState, IRootState>, payload: { clientId: string }) {
        commit('SHOW_DIALOG_LINK_CLIENT_RESPONSIBLE', { show: true });
        const selectedClientResponsible = Object.assign({}, defaultClientResponsible);
        selectedClientResponsible.clientId = payload.clientId;
        commit('SET_CLIENT_RESPONSIBLE', { selectedClientResponsible });
    },
    async linkClientResponsible({ commit, dispatch, state }: ActionContext<ResponsiblesState, IRootState>) {
        try {
            const data = state.selectedClientResponsible;
            const response = await axiosAuth.post<DefaultResponse<Responsibles>>(`${endPoints.responsibles}/link_client`, data);
       
            dispatch('clients/fetchClientResponsible', {clientId: data.clientId}, { root: true });
            commit('SHOW_DIALOG_LINK_CLIENT_RESPONSIBLE', { show: false });
        } catch(error) {
            notifyError('Erro ao linkar responsável doo cliente');
        }
    },
    openFilterDialog({ commit }: ActionContext<ResponsiblesState, IRootState>) {
        commit('SHOW_FILTER_DIALOG', {show: true});
    },
    async fetchResponsibles({ commit, state }: ActionContext<ResponsiblesState, IRootState>,) {
        try {
            let params: any = {};
            const keys = Object.keys(state.filter);
            keys.forEach((key) => {
              if (state.filter[key as keyof IFilterResponsible]) {
                params = { ...params, [key]: state.filter[key as keyof IFilterResponsible] };
              }
            });
            params.page = state.pagination.page;
            params.itemsPerPage = state.pagination.itemsPerPage;
            const response = await axiosAuth.get<DefaultResponse<Responsibles>>(`${endPoints.responsibles}`, { params });
            commit('SET_RESPONSIBLES', { responsibles: response.data.data });
            commit('SET_TOTAL', { total: response.data.total });
        } catch (error) {
            notifyError('Erro ao buscar responsáveis');
        }
    },
    async createResponsible({ commit, dispatch, state }: ActionContext<ResponsiblesState, IRootState>) {
        try {
            let response;
            if (state.formMode === FormMode.ADD) {
                delete state.selectedResponsible.id;
                response = await axiosAuth.post<DefaultResponse<Responsibles>>(endPoints.responsibles, state.selectedResponsible);
                commit('SET_SELECTED_RESPONSIBLE', { responsibles: response.data });
                
            } else {
                response = await axiosAuth.put<DefaultResponse<Responsibles>>(`${endPoints.responsibles}/${state.selectedResponsible.id}`, state.selectedResponsible);
            }
            commit('SHOW_FORM_DIALOG', { show: false });
            notifySuccess('Responsável salvo com sucesso');
            if(state.selectedClientResponsible.clientId) {
                dispatch('clients/fetchClientResponsible', {clientId: state.selectedClientResponsible.clientId}, { root: true });
            } else {
                dispatch('fetchResponsibles');
            }
        } catch (error: any) {
            notifyError(error?.response?.data?.error || 'Erro ao salvar responsável');
        }
    },
    async updateResponsible({ commit, dispatch, state }: ActionContext<ResponsiblesState, IRootState>) {
        try {
            const data = state.selectedResponsible;
            const response = await axiosAuth.put<DefaultResponse<Responsibles>>(`${endPoints.responsibles}/${state.selectedResponsible.id}`, state.selectedResponsible);
            commit('SET_SELECTED_RESPONSIBLE', { responsibles: response.data });
            dispatch('fetchResponsibles');
            commit('SHOW_FORM_DIALOG', { show: false });
        } catch (error) {
            notifyError('Erro ao atualizar responsável');
        }
    },
};

export default {
    namespaced: true,
    state,
    mutations,
    actions
};