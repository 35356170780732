import { format } from 'date-fns';

const apiDbLayout = 'yyyy-MM-dd HH:mm:ss';

export const getNowDBFormat = () => {
  return format(new Date(), apiDbLayout);
}

export const getNow = () => {
  const currentDate = new Date();
  const formattedDate = format(currentDate, apiDbLayout);
  return new Date(formattedDate);
}

export const getLocaleDate = (date: string, digits: number) => {
  if (date) {
    const d = new Date(date)
    return d.toLocaleDateString().substring(0, digits);
  }
  return ''
};

export const getLocaleDateTime = (date: string) => {
  if (date) {
    const d = new Date(date);

    const localeDate = d.toLocaleDateString();
    
    const localeTime = d.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', second: '2-digit' });
    
    return `${localeDate} ${localeTime}`;
  }
  return '';
};


export const formatDateToString = (date: Date) => {
  const yyyy = date.getFullYear();
  const mm = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
  const dd = String(date.getDate()).padStart(2, '0');


  return `${yyyy}-${mm}-${dd}`;
}
export const formatDateTimeToString = (date: Date) => {
  const yyyy = date.getFullYear();
  const mm = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
  const dd = String(date.getDate()).padStart(2, '0');
  const hh = String(date.getHours()).padStart(2, '0');
  const ii = String(date.getMinutes()).padStart(2, '0');
  const ss = String(date.getSeconds()).padStart(2, '0');

  return `${yyyy}-${mm}-${dd} ${hh}:${ii}:${ss}`;
}

export const addOneMinute = (time: string) => {
  // Separar a hora e o minuto da entrada
  const [hours, minutes] = time.split(":").map(Number);

  // Criar um objeto Date baseando-se na hora atual
  const date = new Date();
  date.setHours(hours, minutes, 0, 0); // Configurar a hora e o minuto fornecidos

  // Adicionar um minuto
  date.setMinutes(date.getMinutes() + 1);

  // Formatar de volta no formato "HH:mm"
  const newHours = String(date.getHours()).padStart(2, "0");
  const newMinutes = String(date.getMinutes()).padStart(2, "0");

  return `${newHours}:${newMinutes}`;
}

// Exemplo de uso
const input = "12:59";
const result = addOneMinute(input);
console.log(result); // Saída: "13:00"


export const formatDateTimezone = (date: Date) => {
  // return date in format 2023-04-16T00:00:00-03:00
  const yyyy = date.getFullYear();
  const mm = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
  const dd = String(date.getDate()).padStart(2, '0');
  const hh = String(date.getHours()).padStart(2, '0');
  const ii = String(date.getMinutes()).padStart(2, '0');
  const ss = String(date.getSeconds()).padStart(2, '0');

  // Calculate the timezone offset in hours and minutes
  const timezoneOffset = -date.getTimezoneOffset();
  const tzHours = String(Math.abs(Math.floor(timezoneOffset / 60))).padStart(2, '0');
  const tzMinutes = String(Math.abs(timezoneOffset % 60)).padStart(2, '0');
  const tzSign = timezoneOffset >= 0 ? '+' : '-';

  return `${yyyy}-${mm}-${dd}T${hh}:${ii}:${ss}${tzSign}${tzHours}:${tzMinutes}`;
}

export const getDateByDayAndHour = (dayOfWeek: number, hour: string) => {
  const now = new Date();

  const currentDayOfWeek = now.getDay() === 0 ? 6 : now.getDay() - 1;

  const dayDifference = dayOfWeek - currentDayOfWeek;

  const targetDate = new Date(now);
  targetDate.setDate(now.getDate() + dayDifference);

  const [hours, minutes, seconds] = hour.split(':').map(Number);
  targetDate.setHours(hours, minutes, seconds, 0);

  return formatDateTimeToString(targetDate);
}

export const getDateFromUrl = () => {
  const url = window.location.href;

  const regex = /\/services_schedule\/(\d{4}-\d{2}-\d{2})/;
  const match = url.match(regex);

  return match ? match[1] : null;
}

export const daysOfWeek = [
  {
    id: 0,
    description: "Segunda",
  },
  {
    id: 1,
    description: "Terça",
  },
  {
    id: 2,
    description: "Quarta",
  },
  {
    id: 3,
    description: "Quinta",
  },
  {
    id: 4,
    description: "Sexta",
  },
  {
    id: 5,
    description: "Sábado",
  },
  {
    id: 6,
    description: "Domingo",
  },
];