import { FormMode } from "@/consts/form";
import { StockControlState } from "../types/state";
import { ActionContext } from "vuex";
import axiosAuth from "@/config/axios";
import { DefaultResponse } from "@/types/response";
import { endPoints } from "@/config/api";
import { useNotification } from "@/composables/useNotification";
import { IFilterStockControl, ReplaceModes, StockControlItem } from "../types/stock_control";
import { StockFilterModes, StockTypes } from "../consts";
import { IRootState } from "@/types/state";

const { notifySuccess, notifyError } = useNotification();

const defaultStockControlItem: StockControlItem = {
    clientId: undefined,
    date: new Date(),
    minQuantity: 0,
    quantity: 0,
    isNew: true,
    quantityReplaced: 0,
    stockItemId: undefined,
    type: StockTypes.INTERNAL,
    replaceMode: ReplaceModes.ADD,
    resetEstimate: true,
    createdAt: new Date(),
    createdBy: '',
    updatedAt: null,
    updatedBy: null
};

const defaultStockControlFilter: IFilterStockControl = {
    clientId: '',
    minQuantity: undefined,
    stockItemId: undefined,
    mode: StockFilterModes.POSITION
};

const state: () => StockControlState = () => ({
    filterStockControl: Object.assign({}, defaultStockControlFilter),
    stockControlItems: [],
    pagination: {
        page: 1,
        itemsPerPage: 10,
        total: 0
    },
    selectedStockControlItem: Object.assign({}, defaultStockControlItem),
    formMode: FormMode.ADD,
    showFilterDialog: false,
    showFormDialog: false,
    showDeleteDialog: false,
    showReplaceDialog: false,
    replaceMode: ReplaceModes.ADD
});

const mutations = {
    SET_FILTER(state: StockControlState, payload: { filter: IFilterStockControl }) {
        state.filterStockControl = payload.filter
    },
    SHOW_DELETE_DIALOG(state: StockControlState, payload: { show: boolean }) {
        state.showDeleteDialog = payload.show;
    },
    SHOW_FILTER_DIALOG(state: StockControlState, payload: { show: boolean }) {
        state.showFilterDialog = payload.show;
    },
    SET_STOCK_CONTROL_ITEMS(state: StockControlState, payload: { stockControlItems: StockControlItem[] }) {
        state.stockControlItems = payload.stockControlItems;
        console.log('state.stockControlItems', state.stockControlItems);
    },
    SHOW_FORM_DIALOG(state: StockControlState, payload: { show: boolean }) {
        state.showFormDialog = payload.show;
    },
    SET_SELECTED_STOCK_CONTROL_ITEM(state: StockControlState, payload: { stockControlItem: StockControlItem }) {
        state.selectedStockControlItem = Object.assign({}, payload.stockControlItem);
    },
    SET_FORM_MODE(state: StockControlState, payload: { mode: FormMode }) {
        state.formMode = payload.mode;
    },
    SHOW_REPLACE_DIALOG(state: StockControlState, payload: { show: boolean }) {
        console.log('payload', payload.show)
        state.showReplaceDialog = payload.show;
    },
    SET_TOTAL(state: StockControlState, payload: { total: number }) {
        state.pagination.total = payload.total;
    },
};

const actions = {
    closeDialog({ commit }: ActionContext<StockControlState, IRootState>) {
        commit('SHOW_FORM_DIALOG', { show: false });
        commit('SHOW_REPLACE_DIALOG', { show: false });
    },
    closeFilterDialog({ commit }: ActionContext<StockControlState, IRootState>) {
        commit('SHOW_FILTER_DIALOG', { show: false });
    },
    openCreateDialog({ commit }: ActionContext<StockControlState, IRootState>) {
        const item = Object.assign({}, defaultStockControlItem);
        item.date = new Date();
        item.quantity = undefined;
        item.isNew = true;
        item.resetEstimate = true;
        commit('SET_FORM_MODE', { mode: FormMode.ADD });
        commit('SET_SELECTED_STOCK_CONTROL_ITEM', { stockControlItem: item });
        commit('SHOW_FORM_DIALOG', { show: true });
      },
    openEditDialog({ commit }: ActionContext<StockControlState, IRootState>, payload: { stockControlItem: StockControlItem }) {
        payload.stockControlItem.date = new Date(payload.stockControlItem.date);
        commit('SET_FORM_MODE', { mode: FormMode.EDIT });
        commit('SET_SELECTED_STOCK_CONTROL_ITEM', payload);
        commit('SHOW_FORM_DIALOG', { show: true });
    },
    openViewDialog({ commit }: ActionContext<StockControlState, IRootState>,  payload: { stockControlItem: StockControlItem }) {
        payload.stockControlItem.date = new Date(payload.stockControlItem.date);
        commit('SET_FORM_MODE', { mode: FormMode.VIEW });
        commit('SET_SELECTED_STOCK_CONTROL_ITEM', payload);
        commit('SHOW_FORM_DIALOG', { show: true });
    },
    openReplaceDialog({ commit }: ActionContext<StockControlState, IRootState>,  payload: { stockControlItem: StockControlItem, replaceMode: ReplaceModes }) {
        const item = Object.assign({}, payload.stockControlItem);
        item.lastDate = new Date(item.date);
        item.lastQuantity = item.quantity;
        item.date = new Date();
        item.isNew = false;
        item.id = undefined;
        item.resetEstimate = false;
        item.quantityReplaced = undefined;
        item.replaceMode = payload.replaceMode;
        commit('SET_SELECTED_STOCK_CONTROL_ITEM', { stockControlItem: item });
        commit('SHOW_REPLACE_DIALOG', { show: true });
    },
    openDeleteDialog({ commit }: ActionContext<StockControlState, IRootState>, payload: { stockControlItem: StockControlItem }) {
        commit('SHOW_DELETE_DIALOG', { show: true });
        commit('SET_SELECTED_STOCK_CONTROL_ITEM', { stockControlItem: payload.stockControlItem });
    },
    async calcStockEstimate({ commit, state }: ActionContext<StockControlState, IRootState>, payload: { stockControlItem: StockControlItem }) {
        try {
            const { id } = state.selectedStockControlItem;
            await axiosAuth.put(`${endPoints.stock_control}/estimate/${payload.stockControlItem.id}`);
            notifySuccess('Estimativa calculada com sucesso');
        } catch (error) {
            notifyError('Erro ao calcular estimativa');
        }
    },
    async fetchStockControls({ commit, state }: ActionContext<StockControlState, IRootState>) {
        try {
            const params: any = {};
           
            for(const key in state.filterStockControl) {
                const value = state.filterStockControl[key as keyof typeof state.filterStockControl];
                if (value || value === 0) {
                    params[key] = value;
                }
            }
            params.page = state.pagination.page;
            params.itemsPerPage = state.pagination.itemsPerPage;
            
            const response = await axiosAuth.get<DefaultResponse<StockControlItem>>(`${endPoints.stock_control}`, { params });
            commit('SHOW_FILTER_DIALOG', { show: false });
            commit('SET_STOCK_CONTROL_ITEMS', { stockControlItems: response.data.data });
            commit('SET_TOTAL', { total: response.data.total });
        } catch (error) {
            notifyError('Erro ao buscar itens do estoque');
        }
    },
    async createStockControl({ commit, dispatch, state }: ActionContext<StockControlState, IRootState>) {
        try {
          await axiosAuth.post(`${endPoints.stock_control}`, state.selectedStockControlItem);
          notifySuccess('Estoque criado com sucesso');
          commit('SHOW_FORM_DIALOG', { show: false });
          commit('SHOW_REPLACE_DIALOG', { show: false });
          dispatch('fetchStockControls');
        } catch (error) {
          notifyError('Erro ao criar item do estoque');
        }
    },
    async updateStockControl({ commit, dispatch, state }: ActionContext<StockControlState, IRootState>) {
        const { id } = state.selectedStockControlItem;
        try {
          await axiosAuth.put(`${endPoints.stock_control}/${id}`, state.selectedStockControlItem);
          notifySuccess('Estoque atualizado com sucesso');
          commit('SHOW_FORM_DIALOG', { show: false });
          dispatch('fetchStockControls');
        } catch (error) {
          notifyError('Erro');
          console.error('Erro ao atualizar item do estoque:', error);
        }
    },
    async deleteStockControl({ commit, dispatch, state }: ActionContext<StockControlState, IRootState>, payload: {stock_items: StockControlItem}) {
        try {
          await axiosAuth.delete(`${endPoints.stock_control}/${state.selectedStockControlItem.id}`);
          notifySuccess('Estoque deletado com sucesso');
          commit('SHOW_DELETE_DIALOG', { show: false });
          dispatch('fetchStockControls');
        } catch (error) {
          notifyError('Erro');
          console.error('Erro ao deletar item do estoque:', error);
        }
    },
};

export default {
    namespaced: true,
    state,
    mutations,
    actions
};