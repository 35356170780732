<template>
  <v-layout class="rounded rounded-md">
    <MenuSystem v-if="logged" class="h-100" />
    <v-main class="d-flex" style="min-height: 300px; max-width: 100%;">
      <router-view />
    </v-main>
    <v-dialog v-model="dialogChangePassword" 
      max-width="600"
      @click:outside="hideDialogChangePassword"
    >
      <change-password/>
    </v-dialog>
  </v-layout>
</template>

<script>
import { ref } from "vue";
import { useTheme } from "vuetify";
import { useStore } from "vuex";
import { defineComponent } from "vue";
import MenuSystem from "./components/menu/MenuSystem.vue";
import { computed } from "vue";
import './config/app.scss';
import validationRules from "@/utils/rules";
import ChangePassword from "./components/password/ChangePassword.vue";

export default defineComponent({
  components: {
    ChangePassword,
    MenuSystem,
  },
  setup() {
    const store = useStore();
    const theme = useTheme();
    const darkMode = ref(true);
    const formValid = ref(false);
    const toggleTheme = () => {
      theme.global.name.value = darkMode.value ? "dark" : "light";

    };
    const logged = computed(() => store?.getters["auth/isLogged"]);
    const changePasswordData = computed(() => store.state.auth.changePasswordData);
    const dialogChangePassword = computed(() => store.state.auth.showDialogChangePassword);

    const hideDialogChangePassword = () => {
      store.commit("auth/SHOW_DIALOG_CHANGE_PASSWORD", { show: false });
    }

    const submit = async (event) => {
      let eventValidation = await event;
      if(eventValidation.valid) {
        store.dispatch("auth/changePassword");
      }
    }

    return {
      submit,
      changePasswordData,
      formValid,
      logged,
      toggleTheme,
      hideDialogChangePassword,
      dialogChangePassword,
      validationRules
    };
  },
});
</script>


<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding-left: 5px;
  padding-right: 5px;
  padding-top: 15px;
  padding-bottom: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
