import { ActionContext } from 'vuex';
import supabase from "@/plugins/supabase"
import { AuthState, ILogin, IProfile, ISession, IUser } from '../types/auth.types';
import router from '@/router'
import { useNotification } from '@/composables/useNotification';
import { IRootState } from '@/types/state';
import axiosAuth from '@/config/axios';

const { notifySuccess, notifyError } = useNotification();

const LOCAL_STORAGE_ITEM = 'session-jl'

const defaultSession : ISession = {
    access_token: '',
    expires_at: 0,
    expires_in: 0,
    refresh_token: '',
    token_type: '',
    stay_connected: false
}
const defaultProfile : IProfile = {
    created_at: '',
    created_by: '',
    doc: '',
    name: '',
    role_id: '',
    updated_at: '',
    updated_by: '',
    user_id: ''
}

const state: () => AuthState = () => ({
    changePasswordData: {
        password: '',
        newPassword: '',
        confirmationPassword: ''
    },
    logged: false,
    menus: [],
    profile: defaultProfile,
    session: defaultSession,
    showDialogChangePassword: false,
    user: {
        id: '',
        email: ''
    }
});

const isExpired = (expires_at: number) => {
    const now = new Date().getTime()
    return expires_at < (now / 1000)
}

const getters = {
    isLogged: (state: AuthState) => state.logged,
    isTokenExpired(state: AuthState) {
        const now = new Date().getTime()
        return state.session.expires_at > now
    },
}

const mutations = {
    setLogged(state: AuthState, payload: {logged: boolean}) { 
        state.logged = payload.logged;
    },
    setProfile(state: AuthState, payload: {profile: IProfile}) { 
        state.profile = payload.profile;
    },
    setUser(state: AuthState, payload: {user: IUser}) {
        state.user = payload.user;
    },
    setSession(state: AuthState, payload: {session: ISession, stayConnected: boolean}) {
        state.session = payload.session;
        state.session.stay_connected = payload.stayConnected;
    },
    SHOW_DIALOG_CHANGE_PASSWORD(state: AuthState, payload: {showDialogChangePassword: boolean}) {
        state.showDialogChangePassword = payload.showDialogChangePassword;
    },
    SET_CHANGE_PASSWORD_DATA(state: AuthState, payload: {changePasswordData: {password: string, newPassword: string, confirmationPassword: string}}) {
        state.changePasswordData = payload.changePasswordData;
    }
};

const actions = {
    async login({ commit, dispatch }: ActionContext<AuthState, IRootState>, payload: ILogin) {
        try {
            const loginResponse = await supabase.auth.signInWithPassword({
                email: payload.email,
                password: payload.password
            })

            const user = { email: loginResponse.data.user?.email, id: loginResponse.data.user?.id }

            await commit('setSession', {session: loginResponse.data.session, stayConnected: false})
            await commit('setUser', {user})
            
            localStorage.setItem(LOCAL_STORAGE_ITEM, JSON.stringify({session: loginResponse.data.session, user: user}))
            dispatch('getProfile', {user_id: loginResponse.data.user?.id})
            
            await dispatch('menu/getMenus', {}, {root: true})
            dispatch('menu/openMenu', {menu: {link: '/dashboard'}}, {root: true})
            await commit('setLogged', {logged: true})
        } catch(e) {
            notifyError('Erro ao realizar o login');
        }
    },
    async changePassword({ state, commit }: ActionContext<AuthState, IRootState>) {
        try {
            console.log(state)
            const loginResponse = await supabase.auth.signInWithPassword({
                email: state.user.email,
                password: state.changePasswordData.password
            })
            if(loginResponse.error) {
                notifyError('Erro ao alterar a senha. Confira a senha informada');
            } else {
                const result = await supabase.auth.updateUser({
                    password: state.changePasswordData.newPassword
                })
                if (result.data) {
                    notifySuccess('Senha alterada com sucesso');
                    commit('SHOW_DIALOG_CHANGE_PASSWORD', {showDialogChangePassword: false})
                } else {
                    notifyError('Erro ao alterar a senha');
                }
            }
        } catch(e) {
            notifyError('Erro ao alterar a senha');
        }
    },
    async resetPassword({ commit, dispatch, state }: ActionContext<AuthState, IRootState>, payload: {accessToken: string, refreshToken: string}) {
        try {
            console.log(payload)
            if(!payload.accessToken || !payload.refreshToken) {
                notifyError('Erro ao resetar a senha');
                return
            }

            const result = await supabase.auth.setSession({access_token: payload.accessToken, refresh_token: payload.refreshToken})
          
            if (result.error) {
                notifyError('Erro ao resetar a senha');
            } else {
                notifySuccess('Senha resetada com sucesso');
            }
            const resultChangePassword = await supabase.auth.updateUser({
                password: state.changePasswordData.newPassword
            })
            if (resultChangePassword.data) {
                notifySuccess('Senha alterada com sucesso');
                dispatch('unauthorized')
            } else {
                notifyError('Erro ao alterar a senha');
            }
        } catch(e) {
            notifyError('Erro ao resetar a senha');
        }
    },
    async sendResetPassword({ commit, dispatch }: ActionContext<AuthState, IRootState>, payload: {email: string}) {
        try {
            const { data, error } = await supabase.auth.resetPasswordForEmail(payload.email, {
                redirectTo: 'https://sistema.jlconservadora.com.br/redirect',
            })
            if (error) {
                notifyError('Erro ao enviar o email de reset de senha');
            } else {
                notifySuccess('Email de reset de senha enviado com sucesso');
            }
        } catch(e) {
            notifyError('Erro ao enviar o email de reset de senha');
        }
        
    },
    async getProfile({ commit }: ActionContext<AuthState, IRootState>, payload: {user_id: string}) {
        try {
            const resultProfile = await supabase.from('profiles').select('*').eq('user_id', payload.user_id).single()
            commit('setProfile', { profile: resultProfile.data })
        } catch(e) {
            notifyError('Erro ao recuperar o perfil do usuário');
        }
    },
    async recoverToken({ commit, dispatch }: ActionContext<AuthState, IRootState>) {
        const session = localStorage.getItem(LOCAL_STORAGE_ITEM)
        if (session) {
            const sessionData : {
                session: ISession,
                user: IUser
            }  = JSON.parse(session)
            
            if (isExpired(sessionData.session.expires_at)) {
                if (sessionData.session.stay_connected) {
                    await dispatch('refreshToken', { refreshToken: sessionData.session.refresh_token })
                } else {
                    dispatch('unauthorized')
                }
            } else {
                await commit('setUser', {user: sessionData.user})
                await commit('setSession', {session: defaultSession, stayConnected: false})
                await commit('setLogged', {logged: true})
                dispatch('getProfile', {user_id: sessionData.user.id})
                dispatch('menu/getMenus', {}, {root: true})
            }
        }
    },
    async openDialogChangePassword({ commit }: ActionContext<AuthState, IRootState>) {
        commit('SHOW_DIALOG_CHANGE_PASSWORD', {showDialogChangePassword: true})
        commit('SET_CHANGE_PASSWORD_DATA', {changePasswordData: {password: '', newPassword: '', confirmationPassword: ''}})
    },
    async unauthorized({ commit, dispatch }: ActionContext<AuthState, IRootState>){
        localStorage.removeItem(LOCAL_STORAGE_ITEM)
        await commit('setLogged', {logged: false})
        await commit('setProfile', {profile: defaultProfile})
        await commit('setUser', {user: {id: '', email: ''}})
        await commit('setSession', {session: defaultSession, stayConnected: false})
        dispatch('menu/openMenu', {menu: {link: '/login'}}, {root: true})
    }
};

export default {
    namespaced: true,
    actions,
    mutations,
    getters,
    state,
};
