import { RouteRecordRaw } from 'vue-router';

const employeeSubstitutionsRoutes : Array<RouteRecordRaw> = [
    {
        path: '/substitutes',
        name: 'substitutes',
        component: () => import( './views/EmployeeSubstitutionsView.vue'),
        meta: {
            requiresAuth: true
        }
    },
    
]

export default employeeSubstitutionsRoutes;