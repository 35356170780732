import { StockFilterModes } from "../consts";

export enum ReplaceModes {
    ADD = 1,
    REMOVE = 2
}

export interface StockControlItem {
    id?: string;
    clientId?: string;
    date: Date;
    minQuantity: number;
    quantity?: number;
    lastDate?: Date;
    lastQuantity?: number;
    stockItemId?: string ;
    quantityReplaced?: number;
    estimatedConsumption?: number;
    isNew: boolean;
    type: number;
    replaceMode: ReplaceModes;
    resetEstimate: boolean;
    totalDays?: number;
    createdBy: string;
    createdAt: Date;
    updatedAt: Date | null;
    updatedBy: string | null;
}

export interface IFilterStockControl {
    clientId?: string;
    minQuantity?: number;
    stockItemId?: string;
    mode: StockFilterModes;
}