import { TypesAbsences } from "../consts";
import { EmployeeSubstitution, IFilterEmployeeSubstitutions } from "../types/employee_substitution";

export const TYPES_ABSENCES_OPTIONS = [
    { text: "Férias", value: TypesAbsences.FERIAS },
    { text: "Atestado", value: TypesAbsences.ATESTADO },
    { text: "Folga", value: TypesAbsences.FOLGA },
    { text: "Licença", value: TypesAbsences.LICENCA },
    { text: "Falta", value: TypesAbsences.FALTA },
    { text: "Outros", value: TypesAbsences.OUTROS }
];

export const defaultEmployeeSubstitution: EmployeeSubstitution = {
    id: '',
    fixedEmployee: null,
    substituteEmployee: null,
    fixedEmployeeId: '',
    substituteEmployeeId: '',
    typeAbsence: 0,
    startDate: new Date(),
    endDate: new Date(),
    reason: '',
    clientsExceptions: [],
    exceptions: [],
    substitutionAllClients: true,
    createdBy: '',
    updatedAt: null,
    updatedBy: null,
    createdAt: null
};

export const defaultFilterEmployeeSubstitutions : IFilterEmployeeSubstitutions = {
    fixedEmployeeId: '',
    substituteEmployeeId: '',
    typeAbsence: null,
    startDate: null,
    endDate: null
}