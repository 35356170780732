export const SUPABASE_API_URL = process.env?.VUE_APP_SUPABASE_URL || '';

export const API_URL = process.env?.VUE_APP_API_URL || '';

export const endPoints = {
    avaliations: API_URL + '/avaliations',
    employees_avaliations: API_URL + '/employees_avaliations',
    employees: API_URL + '/employees',
    employee_substitutions: API_URL + '/employee_substitutions',
    client_employee: API_URL + '/client_employee',
    clients: API_URL + '/clients',
    responsibles: API_URL + '/responsibles',
    services: API_URL + '/services',
    stock_items: API_URL + '/stock/items',
    stock_control: API_URL + '/stock/control',
}