import { IEventClientScheduleDTO } from "../types/events";

export const getDistinctDaysByEvents = (events: {start: Date, end: Date}[]): number[] => {
    const days = [0, 1, 2, 3, 4];
    for(const event of events){
        let dateOfWeekEvent = event.start.getDay();
        if(event.start.getDay() === 0){
            dateOfWeekEvent = 6;
        } else {
            dateOfWeekEvent = event.start.getDay() - 1;
        }
 
        if(!days.includes(dateOfWeekEvent)){
            days.push(dateOfWeekEvent);
        }
    }
    return days;
}