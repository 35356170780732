<template>
       <v-form @submit.prevent="submit" ref="formValid">
        <v-card>
          <v-card-title class="mt-2" style="text-align: center">
            <span class="headline text-h5">Alterar senha</span>
          </v-card-title>
          <v-card-content>
            <v-container>
              <v-row>
                <v-col cols="12" v-if="needCurrentPassword">
                  <v-text-field
                    v-model="changePasswordData.password"
                    :rules="[validationRules.required, validationRules.minLength(changePasswordData.password, 6)]"
                    label="Senha Atual"
                    type="password"
                    required 
                   
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    v-model="changePasswordData.newPassword"
                    :rules="[validationRules.required, validationRules.minLength(changePasswordData.newPassword, 8)]"
                    label="Nova Senha"
                    type="password"
                    required
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    v-model="changePasswordData.confirmationPassword"
                    :rules="[validationRules.required, validationRules.isEqual(changePasswordData.newPassword, changePasswordData.confirmationPassword)]"
                    label="Confirmação de nova senha"
                    type="password"
                    required
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </v-card-content>
          <v-card-actions>
            <v-btn @click="hideDialogChangePassword" color="red" text>
              Cancelar
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn color="primary" type="submit">
              Salvar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
</template>

<script>
import { defineComponent, ref, computed } from "vue";
import { useStore } from "vuex";
import validationRules from "@/utils/rules";

export default defineComponent({
    props: {
        accessToken: {
            type: String,
            default: ''
        },
        refreshToken: {
            type: String,
            default: ''
        },
        needCurrentPassword: {
            type: Boolean,
            default: true
        }
    },
    setup(props) {
        const store = useStore();
        const formValid = ref(false);

        const logged = computed(() => store?.getters["auth/isLogged"]);
        const changePasswordData = computed(() => store.state.auth.changePasswordData);
        const dialogChangePassword = computed(() => store.state.auth.showDialogChangePassword);

        const hideDialogChangePassword = () => {
            store.commit("auth/SHOW_DIALOG_CHANGE_PASSWORD", { show: false });
        }

        const submit = async (event) => {
            let eventValidation = await event;
            if(eventValidation.valid) {
                if(props.needCurrentPassword) {
                    store.dispatch("auth/changePassword");
                } else {
                    store.dispatch("auth/resetPassword", {accessToken: props.accessToken, refreshToken: props.refreshToken});
                }
            }
        }

        return {
            submit,
            changePasswordData,
            formValid,
            logged,
            hideDialogChangePassword,
            dialogChangePassword,
            validationRules,
        };
    }
})

</script>